<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Liste des commandes fournisseur</h5>
        <Toolbar class="mb-4" v-if="Permissions.add">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Ajouter une commande" icon="pi pi-plus" class="p-button-success mr-2"
                @click="showAddCommand()" />
            </div>
          </template>
          <template v-slot:end>
            <Button type="button" label="Filtre" icon="pi pi-filter" @click="toggle" class="p-button-info" />
            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" style="width: 600px">
              <div class="card">
                <h5>Date</h5>
                <Calendar v-model="datefilter" selectionMode="range" />

                <Divider />
                <h5>Caissier</h5>
                <Dropdown v-model="selectedCiasse" :options="ciasses" optionLabel="full_name" />

                <Divider />
                <h5>Fournisseur</h5>
                <Dropdown v-model="selectedclient" :options="clients" optionLabel="rsocial" />

                <Divider />
                <h5>statut de la commande</h5>

                <div class="p-fluid formgrid grid">
                  <div v-for="status in statuts" :key="status" class="field col-12 md:col-6">
                    <div>
                      <Checkbox id="checkOption1" name="option" :value="status.id" v-model="statusValue" />
                      <label for="checkOption1">{{ status.nomStatut }}</label>
                    </div>
                  </div>
                </div>
                <Button label="appliquer" @click="filter" class="mr-2 mb-2 p-button-info"></Button>
                <Button label="Initialiser" class="mr-2 mb-2" @click="initfilterP"></Button>
              </div>
            </OverlayPanel>
          </template>
        </Toolbar>
        <DataTable :value="orders" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse" responsiveLayout="scroll" :loading="loading1" :filters="filters1"
          :paginator="true" class="p-datatable-gridlines" :rows="10" :rowHover="true" :globalFilterFields="[
            'client_id',
            'total',
            'prix_reste',
            'is_regler',
            'modeDePaiement',
            'date_expidition',
            'id',
            'nomStatut',
            'nomStatut',
            'user_passe_commande',
          ]">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText v-model="filters1['global'].value" placeholder="Keyword Search" style="width: 100%" />
              </span>
            </div>
          </template>
          <template #empty> Aucun commande trouvé. </template>
          <template #loading>
            Chargement des données commandes. Veuillez patienter.
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="id" header="#ID" sortable>
            <template #body="slotProps">
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="rsocial" header="Fournisseur" sortable>
            <template #body="slotProps">
              {{ slotProps.data.rsocial }}
            </template>
          </Column>

          <Column field="total" header="Total" sortable>
            <template #body="slotProps">
              {{ slotProps.data.total }}
            </template>
          </Column>
          <Column field="prix_reste" header="Le reste" sortable>
            <template #body="slotProps">
              {{ slotProps.data.prix_reste }}
            </template>
          </Column>
          <Column field="nomStatut" header="Statut de la commande" sortable>
            <template #body="slotProps">
              {{ slotProps.data.nomStatut }}
            </template>
          </Column>
          <Column field="is_regler" header="Statuts de paiement" sortable>
            <template #body="slotProps">
              <div v-if="slotProps.data.is_regler == 1" class="success" style="color: green">
                Reglé
              </div>
              <div v-else class="danger s">Non Reglé</div>
            </template>
          </Column>
          <Column field="user_passe_commande" header="Caissier " sortable></Column>
          <Column field="modeDePaiement" header="Mode de paiement" sortable></Column>

          <Column field="date_expidition" header="date d'expedition" sortable></Column>
          <Column header="Actions">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button v-if="Permissions.update" style="margin-right: 6px" icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info" @click="updateOrder(data.data.id)" />
                <!-- <Button
                  style="margin-right: 6px"
                  v-if="Permissions.update && data.data.is_regler == 0"
                  icon="pi pi-wallet"
                  :label="data.data.is_regler"
                  class="p-button-raised p-button-rounded p-button-warning"
                />
                <Button
                  style="margin-right: 6px"
                  v-if="Permissions.update && data.data.is_regler == 1"
                  icon="pi pi-wallet"
                  class="p-button-raised p-button-rounded p-button-warning"
                  disabled
                /> -->
                <!-- <Button
                  style="margin-right: 6px"
                  v-if="Permissions.addRet"
                  @click="showrefCmd(data.data.id)"
                  icon="pi pi-reply"
                  class="p-button-raised p-button-rounded p-button-warning"
                /> -->
                <Button v-if="Permissions.delete" icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger" @click="deleteOrder(data.data.id)" />
              </span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5>Produit de la commande № {{ slotProps.data.id }}</h5>
              <DataTable :value="slotProps.data.commandeLigne" responsiveLayout="scroll">
                <Column field="nomProduit" header="Produit" sortable></Column>
                <Column field="numSerie" header="Numéro de série" sortable></Column>
                <Column field="qtt" header="quantité" sortable></Column>
                <Column field="prix" header="Prix" sortable>
                  <template #body="slotProps" sortable>
                    {{ parseFloat(slotProps.data.prix) }}
                  </template>
                </Column>
                <Column field="remise" header="Remise" sortable>
                  <template #body="slotProps" sortable>
                    {{ slotProps.data.remise }}
                  </template>
                </Column>
                <Column header="Image">
                  <template #body="slotProps">
                    <img :src="
                      url +
                      slotProps.data.imageProduit
                    " class="shadow-2" width="100" />
                  </template>
                </Column>
                <Column header="Actions">
                  <template #body="data">
                    <span class="p-buttonset">
                      <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                      <!-- <Button
                        style="margin-right: 6px"
                        v-if="Permissions.addRet"
                        @click="
                          showrefCmdLine(data.data, data.data.commande_id)
                        "
                        icon="pi pi-reply"
                        class="
                          p-button-raised p-button-rounded p-button-warning
                        "
                      /> -->

                      <Button v-if="Permissions.delete" icon="pi pi-trash"
                        class="p-button-raised p-button-rounded p-button-danger"
                        @click="deleteline(data.data.id, data.data.commande_id)" />
                    </span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="dialogOfCmdR">
    <template #header>
      <h3>Ajouter Retour de la commande № {{ returcmd.id }}</h3>
    </template>
    <div class="field col-12 md:col-6">
      <label for="address">Motif </label>
      <Textarea v-model="returcmd.note" id="address" rows="4" :class="{ 'is-invalid': returcmd.error }" />
      <small class="p-error" v-if="returcmd.error">
        {{ returcmd.error }}
      </small>
    </div>

    <Button label="Annuler" @click="dialogOfCmdR = false" icon="pi pi-times" class="p-button-text" />
    <Button label="Ajouter Retour" @click="ajouterRetour()" icon="pi pi-check" />
  </Dialog>
  <Dialog v-model:visible="dialogOfCmdRLine">
    <template #header>
      <h3>Ajouter Retour de la commande № {{ returcmdRLine.idcmd }}</h3>
    </template>
    <h5>Produit: {{ returcmdRLine.produit }}</h5>
    <div class="field col-12 md:col-6">
      <label for="address">Quantité retournée </label>
      <InputNumber v-model="returcmdRLine.qtt" :allowEmpty="false" :min="1" />
      <br />
      <small class="p-error" v-if="returcmdRLine.errorQtt">
        {{ returcmdRLine.errorQtt }}
      </small>
    </div>
    <div class="field col-12 md:col-6">
      <label for="address">Motif </label>
      <Textarea v-model="returcmdRLine.note" id="address" rows="4" :class="{ 'is-invalid': returcmdRLine.error }" />
      <small class="p-error" v-if="returcmdRLine.error">
        {{ returcmdRLine.error }}
      </small>
    </div>

    <Button label="Annuler" @click="dialogOfCmdRLine = false" icon="pi pi-times" class="p-button-text" />
    <Button label="Ajouter Retour" @click="ajouterRetourLine()" icon="pi pi-check" />
  </Dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      url:null,
      filters1: null,
      loading1: true,
      Permissions: {
        update: false,
        delete: false,
        add: false,
        show: false,
        addRet: false,
      },
      orders: null,
      expandedRows: [],
      dialogOfCmdR: false,
      returcmd: {
        id: null,
        note: null,
        error: null,
      },
      dialogOfCmdRLine: false,
      returcmdRLine: {
        id: null,
        note: null,
        qtt: null,
        qttReel: null,
        error: null,
        idcmd: null,
        produit: null,
      },

      ciasses: null,
      selectedCiasse: { id: null, full_name: "Tout" },
      statuts: null,
      stat: null,
      datedeb: null,
      datefin: null,
      clients: null,
      selectedclient: { id: null, rsocial: "Tout" },
      statusValue: null,
      datefilter: [],
    };
  },
  productService: null,
  created() {
    this.initFilters1();
  },
  mounted() {
    this.url = this.$Gurl;
    // this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    this.Permissions.addRet =
      localStorage.permissionNames.includes("Ajouter retour");
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier commande");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer commande");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste commande");
    if (this.Permissions.show) {
      this.showAllCmd();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    showAddCommand() {
      this.$router.push({ name: "supplierorder" });
    },
    initfilterP() {
      this.datefilter = [];
      this.selectedCiasse = { id: null, full_name: "Tout" };
      this.selectedclient = { id: null, rsocial: "Tout" };
      this.statusValue = null;
    },
    filter() {
      this.loading1 = true;
      console.log(this.datefilter);
      console.log("selectedclient", this.selectedclient);
      console.log("selectedclient", this.statusValue);
      console.log("selectedclient", this.selectedCiasse);

      moment(this.dateEx).format("YYYY-MM-DD HH:mm:ss");
      var filter = {
        user_passe_commande_id: null,
        client_id: null,
        status: null,
        dateD: null,
        dateF: null,
      };
      if (this.selectedCiasse) {
        filter.user_passe_commande_id = this.selectedCiasse.id;
      } else {
        filter.user_passe_commande_id = null;
      }
      if (this.selectedclient.id) {
        filter.client_id = this.selectedclient.id;
      } else {
        filter.client_id = null;
      }
      if (this.statusValue) {
        filter.status = this.statusValue;
      } else {
        filter.status = null;
      }
      if (this.datefilter.length > 0) {
        this.dateD = moment(this.datefilter[0]).format("YYYY-MM-DD");
        this.dateF = this.dateD;
        if (this.datefilter[1] != null) {
          this.dateF = moment(this.datefilter[1]).format("YYYY-MM-DD");
          console.log(this.datefilter)
        }

      } else {
        this.dateD = null;
        this.dateF = null;
      }
      filter.dateD = this.dateD;
      filter.dateF = this.dateF;
      axios
        .post("filterListeCommandesClientRachidFournisseur", filter)
        .then((response) => {
          console.log(response);
          this.orders = response.data.commandes;
          this.loading1 = false;
        }).catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      this.$refs.op.hide();
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    print() {
      window.print();
    },
    ajouterRetourLine() {
      var test = true;
      this.returcmdRLine.errorQtt = null;
      this.returcmdRLine.error = null;
      if (this.returcmdRLine.qtt > this.returcmdRLine.qttReel) {
        this.returcmdRLine.errorQtt =
          "la Quantité retournée doit d'etre inferieur de " +
          this.returcmdRLine.qttReel;
        test = false;
      }
      if (this.returcmdRLine.note == null || this.returcmdRLine.note == "") {
        this.returcmdRLine.error = "Motif du retour est obligatoire.";
        test = false;
      }
      if (test) {
        axios
          .post("RetourlignCommande/" + this.returcmdRLine.id, {
            qtt: this.returcmdRLine.qtt,
            note: this.returcmdRLine.note,
          })
          .then((response) => {
            console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        this.dialogOfCmdRLine = false;
      }
    },
    // showrefCmdLine(line, idcmd) {
    //   this.returcmdRLine.idcmd = idcmd;
    //   this.returcmdRLine.id = line.id;
    //   this.returcmdRLine.qttReel = line.qtt;
    //   this.returcmdRLine.qtt = line.qtt;
    //   this.returcmdRLine.error = null;
    //   this.returcmdRLine.note = null;
    //   this.returcmdRLine.produit = line.nomProduit;
    //   this.dialogOfCmdRLine = true;
    // },
    ajouterRetour() {
      if (this.returcmd.note == null || this.returcmd.note == "") {
        this.returcmd.error = "Motif du retour est obligatoire.";
      } else {
        axios
          .post("RetourCommande/" + this.returcmd.id, {
            note: this.returcmd.note,
          })
          .then((response) => {
            console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        this.dialogOfCmdR = false;
      }
    },
    // showrefCmd(idcmd) {
    //   this.returcmd.id = idcmd;
    //   this.returcmd.error = null;
    //   this.returcmd.note = null;
    //   this.dialogOfCmdR = true;
    // },
    updateOrder(id) {
      this.$router.push({ name: "updateorderfour", params: { id: id } });
    },
    deleteOrder(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerCommande/" + id)
            .then((response) => {
              var index = this.orders
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.orders.splice(index, 1);
              console.log(response.data);
              this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    deleteline(id, idcmd) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerLigneCommande/" + id)
            .then(() => {
              var index = this.orders
                .map((x) => {
                  return x.id;
                })
                .indexOf(idcmd);
              var index2 = this.orders[index].commandeLigne
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.orders[index].commandeLigne.splice(index2, 1);
              console.log(this.produits);
              this.$swal({
                icon: "success",
                title: "Supprimé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    showAllCmd() {
     // let self = this;
      axios
        .get("ListeCommandesClientRachidFournisseur")
        .then((response) => {
          console.log(response);
          this.orders = response.data.commandes;
          this.ciasses = response.data.users;
          this.ciasses.unshift({ id: null, full_name: "Tout" });
          this.statuts = response.data.statu;
          this.clients = response.data.client;
          this.clients.unshift({ id: null, rsocial: "Tout" });
          this.loading1 = false;
        }).catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Product Expanded",
        detail: event.data.name,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Product Collapsed",
        detail: event.data.name,
        life: 3000,
      });
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      this.$toast.add({
        severity: "success",
        summary: "All Rows Expanded",
        life: 3000,
      });
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({
        severity: "success",
        summary: "All Rows Collapsed",
        life: 3000,
      });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>